<template>
  <div class="projectXinFeng">
    <navHeader :index="3" :idx="parseInt(this.$route.query.type) + 12"></navHeader>

    <div class="top">
      <div class="topInner">
        <!-- <div class="titleName">范泾村</div> -->
        <div class="titleName"><i class="iconfont icon-fanjingcun"></i></div>
        <div class="titleText">
          浙江省嘉善县干窑镇地处长三角生态绿色一体化发展示范区核心区域，“范泾草莓”种植历史悠久。经过多年推广，草莓种植面积不断扩大，从2003年的不到2亩到现在的2000多亩，品种不断丰富，技术不断更新，草莓地的收益也从原来的每亩几千元增加到了现在的每亩三四万元。
        </div>
        <div class="titleText">
          为了积极响应国家乡村振兴战略，加快推进农业农村现代化，进一步发展“范泾草莓”的品质提升和标准化建设，打造具有品牌价值和内涵的“范泾草莓”品牌，加强品牌管理部门在市场上更好的管理“范泾草莓”区域品牌，并扩大其产品市场销售，干窑镇党委多次研讨并主动对接农业科技平台和专业资源。
        </div>
      </div>
    </div>
    <div class="environmentPic">
      <img class="" :src="require(`@/assets/images/project/fj1.png`)" alt="" />
    </div>
    <div class="mainContent">
      <div class="contentArea">
        <div class="textStyle1">
          干窑镇农业镇长叶明介绍，立足范泾草莓区域资源优势和产业特色，以农业产业提升为核心，优化产业布局，构建都市农业产业体系，促进信息化、农业现代化同步发展。通过草莓园统一建设，由政府统一管理，为当地农户提供大棚租赁、种植培训服务，即减少了农民前期资金投入，又解决当地百姓就业问题，以草莓采摘、多种农作物轮种，带动当地经济发展。通过数字化手段，实现可持续生产，不断创新运营模式，为当地百姓打通一条致富之路。去年年底嘉善县成立了草莓产业农合联，今年“范泾草莓”参加了国家优质特色农产品认证评价农业标准化区域服务与推广平台，促进嘉善县农业向现代化、标准化发展转型，打通优质特色农产品与市场对接的“最后一公里”。下一步，干窑镇将全面推进乡村振兴，努力将“范泾草莓”小微产业园建设成为嘉善县域高质量发展示范点建设的“展示窗、试验田、桥头堡”。
        </div>
        <!-- <div class="textStyle2">
          协助相关部门做到全面、有效、及时掌握整个鑫锋村农业规模、生产状态、农业发展趋势，及时把握发展方向，以便快速应对挑战、抓住机遇！此外，对鑫锋村现代新农村的智慧和农业种植的便捷进行了良好的宣传展示。
        </div> -->
        <img class="midPic" :src="require(`@/assets/images/project/fj2.png`)" alt="" />
        <div class="textStyle3">
          通过种植过程中的土壤检测，结合草莓生长情况，掌控草莓各阶段营养需求，做到精准高效的水肥投入，产出优质草莓。与此同时，全程优质的有机水溶肥料投入，并不会造成传统农药肥料使用过度而导致的土壤问题，有机肥养土越养越肥！
        </div>
        <div class="textList">
          <div class="textStyle4">通过一系列可以直接装在标准薄膜大棚上的智控设备，我们能做到：</div>
          <div class="">1、帮助莓农控制、管理草莓种植</div>
          <div class="">2、把范泾莓农特色种植模式进行标准化总结</div>
          <div class="">3、溯源——收集数据，从田间到餐桌，让消费者全程了解，更放心</div>
        </div>

        <div class="videoWrapper">
          <img class="picCover" :src="require(`@/assets/images/project/fj3.png`)" alt="" />
          <div class="videoListItem" @click="videoMobileClick()">
            <img class="videoPic" :src="require(`@/assets/images/home/videoPic3.png`)" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 28px; height: 28px"
              alt=""
            />
          </div>
          <iframe
            id="fram_box"
            frameborder="0"
            src="https://v.qq.com/txp/iframe/player.html?vid=l35118ftieb"
            allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    </div>
    <rightsFooter></rightsFooter>
    <videoModal :videoUrl="videoUrlMobile" v-if="isShowPopup" @close="closePopup"></videoModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navHeader from '@/components/navHeader.vue'
import videoModal from '@/components/videoModal.vue'
import rightsFooter from '@/components/rightsFooter.vue'

export default {
  name: 'ProjectXinFeng',
  components: {
    navHeader,
    videoModal,
    rightsFooter
  },
  data() {
    return {
      videoUrlMobile: 'https://v.qq.com/txp/iframe/player.html?vid=l35118ftieb',
      isShowPopup: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    videoMobileClick() {
      this.isShowPopup = true
    },
    openPopup() {
      this.isShowPopup = true
    },
    closePopup() {
      this.isShowPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
.projectXinFeng {
  // padding-bottom: 100px;
  font-family: 苹方-简;
  font-size: 16px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: 0px;

  color: #333333;
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 484px;
    padding-top: 140px;
    .topInner {
      width: 1200px;
      height: 100%;
      .titleName {
        text-align: center;
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;

        letter-spacing: 0em;

        color: #333333;
        margin-bottom: 64px;
        .iconfont {
          font-size: 40px;
          line-height: 40px;
        }
      }
      .titleText {
        margin-bottom: 40px;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .top {
      height: 100%;
      padding-top: 22.4vw;
      .topInner {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        .titleName {
          text-align: center;
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;

          letter-spacing: 0em;

          color: #333333;
          margin-bottom: 20px;
        }
        .titleText {
          color: #333333;
          padding: 0 20px 0 20px;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 20px;
        }
        .titleText2 {
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }
  }
  .environmentPic {
    width: 100%;
    height: 720px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 1199px) {
    .environmentPic {
      padding: 2vw 0 0vw 0;
      width: 100%;
      height: 42.67vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media only screen and (min-width: 1199px) {
    .mainContent {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      .contentArea {
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .textStyle1 {
          margin: 80px 0 40px 0;
        }
        .textStyle2 {
          margin: 0px 0 80px 0;
        }
        .midPic {
          width: 1200px;
          height: 540px;
        }
        .textStyle3 {
          box-sizing: border-box;
          margin: 80px 0 80px 0;
        }
        .textList {
          width: 1200px;
          .textStyle4 {
            margin-bottom: 40px;
          }
        }

        .videoWrapper {
          padding: 40px 0 64px 0;
          width: 1200px;
          display: flex;
          box-sizing: border-box;
          // display: grid;
          // grid-gap: 60px;
          // grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
          // grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
          .videoListItem {
            display: none;
            width: 360px;
            height: 540px;
          }
          .picCover {
            width: 360px;
            height: 540px;
            margin-right: 40px;
          }
          #fram_box {
            width: 360px;
            height: 540px;
            background: #000000;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .mainContent {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      .contentArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .textStyle1 {
          margin: 40px 0 20px 0;
          padding: 0 20px 0 20px;
          line-height: 24px;
          font-size: 14px;
          font-weight: 300;
        }
        .textStyle2 {
          margin: 0px 0 40px 0;
          line-height: 24px;
          font-size: 14px;
          font-weight: 300;
          padding: 0 20px 0 20px;
        }
        .midPic {
          width: 100%;
          height: 42.67vw;
        }
        .textStyle3 {
          margin: 40px 0 40px 0;
          line-height: 24px;
          font-size: 14px;
          font-weight: 300;
          padding: 0 20px 0 20px;
        }
        .title {
          display: none;
        }
        .textList {
          padding: 0 20px 0 20px;
          font-size: 14px;
          font-weight: 300;
          width: 100%;
          line-height: 24px;
          box-sizing: border-box;
          .textStyle4 {
            margin-bottom: 20px;
          }
        }
        .videoWrapper {
          padding: 20px 0 32px 20px;
          width: 100%;
          display: flex;
          // display: grid;
          // grid-gap: 0px;
          // grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
          // grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
          .picCover {
            width: 33.3vw;
            height: 50vw;
            margin-right: 40px;
          }

          .videoListItem {
            width: 33.3vw;
            height: 50vw;
            cursor: pointer;
            position: relative;
            .videoPic {
              width: 33.3vw;
              height: 50vw;
            }
            .playBtn {
              z-index: 1;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }

          #fram_box {
            display: none;
            width: 33.3vw;
            height: 50vw;
            background: #000000;
          }
        }
      }
    }
  }
}
</style>